import React, { useEffect, useState } from "react"
import "./BlocMaterielSeo.scss"
import config from "../../../../utils/config"
import links from "../../../../utils/links"

const BlockSecurite = ({ data , titre, soustitre, txt1, txt2, txtCTA}) => {
  const [campagne, setCampagne] = useState("")
  const [isTherecampagne, setIsThereCampagne] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const resizeEvent = () => {
    const tablet = 1170
    const mobile = 768

    if (window.innerWidth > tablet) {
      setIsMobile(false)
      return
    }
    if (window.innerWidth <= tablet && window.innerWidth >= mobile) {
      setIsMobile(false)
      return
    }
    if (window.innerWidth <= mobile) {
      setIsMobile(true)
      return
    }
  }
  useEffect(() => {
    resizeEvent()
    if (typeof window !== "undefined") {
      window.addEventListener("resize", () => {
        resizeEvent()
      })
    }
  }, [])
  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage.getItem("campaign") != null){
      setCampagne(localStorage.getItem("campaign"))
      setIsThereCampagne(true)
    }
  }, [])

  const [hide, setHide] = useState(false)
  const [scrollTop, setScrollTop] = useState(false)
  const [temp, setTemp] = useState(false)

  useEffect(() => {
    if (!hide && temp) {
      setScrollTop(true)
      setTemp(false)
      setTimeout(() => {
        setScrollTop(false)
      }, 500)
    }
  }, [hide])
  if (data) {
    return (
      <div className="wrapper ta-c">
        {!isMobile && 
        <div className="mb-4">
          <div className="mb-4">
            <h2 className="titreSeo fs-34 ff-gb c-4">{titre}</h2>
            <div className="ff-gm fs-16 lh-24 mt-2">{soustitre}</div>
          </div>
          <div
            className={"mb-4 block-wording" + (isMobile ? "Mobile" : "")}
            dangerouslySetInnerHTML={{
             __html: txt1,
            }}
          ></div>
          <div className={`block-wording-hide ${hide ? "is-active" : ""} `}>
            <div
              className={"block-wording" + (isMobile ? "Mobile" : "")}
              dangerouslySetInnerHTML={{
               __html: txt2,
              }}
            ></div>
          </div>
        </div>
         }
         {isMobile && 
          <>
          <div className="mb-4">
            <h2 className="titreSeo fs-34 ff-gb c-4">{titre}</h2>
            <div className="ff-gm fs-16 lh-24 mt-2">{soustitre}</div>
          </div>
          <div className={"mb-4 block-wording" + (isMobile ? "Mobile" : "")}
            dangerouslySetInnerHTML={{__html: txt1,}}>
          </div>
          <div className={`block-wording-hide ${hide ? "is-active" : ""} `}>
          <div className={"block-wording" + (isMobile ? "Mobile" : "")}
            dangerouslySetInnerHTML={{__html: txt2,}}>
          </div>
          </div></>
        
         }
        <div className="d-f jc-c py-2 ai-c fd-c">
          <div className="mb-8">
            <button
              onClick={() => {
                setTemp(hide)
                setHide(!hide)
              }}
              className={
                hide
                  ? "btn-show-more btn-select-round c-p d-f fd-r ai-c is-active"
                  : "btn-show-more btn-select-round c-p d-f fd-r ai-c"
              }
              ref={el => {
                if (el) {
                  if (scrollTop) {
                    setTimeout(() => {
                      el.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                      })
                    }, 250)
                  }
                }
              }}
            >
              {hide ? "Afficher moins" : "En savoir plus"}
              <span className="icon-arrow-down-c1 ml-1 arrow" />
            </button>
          </div>
          <a href={links.getLink("diag") + campagne} className="ff-gbold td-n btn-hover-c1 d-f ai-c c-p bc-1 c-w fw-b fs-14 br-24 mBDevisSeo">
            <span
              style={{ height: "20px", width: "20px", minWidth: "20px" }}
              className="ml-3 mr-1 icon-start-diag"
            ></span>
            <span className="mBDevisTxt c-w" dangerouslySetInnerHTML={{__html: txtCTA}}>
            </span>
          </a>
        </div>
      </div>
    )
  }
  return <></>
}

export default BlockSecurite
